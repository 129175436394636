$mainFont: niveau-grotesk, sans-serif;
$textColor: #17405B;


/* 3. Header ================================= */

.headerContainer {
	position: fixed;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 90px;
	background: black;
	z-index: 1000;
	cursor: pointer;

	@media (min-width: 768px) {
		height: 180px;
	}

	@media (min-width: 1440px) {
		height: 140px;
	}

	.headerLogo {
	    background: url(../assets/logos/wordmarkWhite.svg) no-repeat;
	    position: relative;
	    width: 60px;
	    height: 60px;
	    margin: 10px 0px 10px 6%;
	    transition: .4s;
	    transform: rotate(0deg);

		@media (min-width: 768px) {
			width: 110px;
		    height: 110px;
		    margin-left: 4%;
		    margin-top: 7px;
		}

		@media (min-width: 1440px) {
            margin-left: 0;
 		    margin-top: 5px;
		    width: 160px;
		    height: 130px;
		    background-position: center;
		    background-size: 65%;

		    &:hover {
		    	transform-origin: center;
		    	transform: rotate(90deg) scale(.8);
		    	transition: .4s;
		    }
		}

	    a {
	    	position: absolute;
	    	width: 100%;
	    	height: 100%;
	    	margin: 0 auto;
	    }
	}

	.menuContainer {
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
	    margin-top: 0px;
	    right: 8%;
	    width: 40px;
	    height: 40px;
	    border: 2px solid transparent;
	    border-radius: 50%;
	    transition: .4s;

		@media (min-width: 768px) {
            right: 5%;
		    width: 60px;
		    height: 62px;
		}

		@media (min-width: 1440px) {
            right: 2%;
		    width: 50px;
		    height: 54px;
		}

	    &.menuCircle {
	    	border: 3px solid white;
	    	transition: .4s;
	    	transform-origin: center;
	    	transform: scale(1.15) rotate(90deg);

	    	@media (min-width: 768px) {
			   	border: 4px solid white;
			}
	    }

		.menuLine {
			position: relative;
		    margin: 0 auto;
		    width: 100%;
		    height: 3px;
		    background: white;
			transition: .4s;
			transform-origin: center;
			opacity: 1;

			@media (min-width: 768px) {
			    height: 4px;
			}

			&.menuLineBlue {
				background: #26ffba;
				transition-delay: 0;
			}

		}

		.topLine {
			transition-delay: .2s;

			&.topClose {
				opacity: 0;
				transition: .4s;
			}
		}

		.midLine {
			&.midClose {
				transform: rotate(135deg);
				transition: .6s;
				width: 70%;
			}	
		}

		.midLine2 {
			position: absolute;
			margin-top: -1px;
			opacity: 0;

			&.midClose2 {
				transform: rotate(45deg);
				transition: .6s;
				width: 70%;
				opacity: 1;

				@media (min-width: 768px) {
				    margin-top: 0px;
				}
			}	
		}

		.bottomLine {
			transition-delay: .2s;

			&.bottomClose {
		   	 	opacity: 0;
		   	 	transition: .4s;
			}    
		}
	}
}



.dropDownContainer {
    position: fixed;
    margin-top: 0px;
    left: 0;
    height: 100%;
    width: 100%;
    background: black;
    z-index: 10;
    transition-timing-function: cubic-bezier(.27,.21,.21,1);
    transition: .5s;
    transition-delay: .55s;
    opacity: 0;
    z-index: -100;

    &.expand {
    	opacity: 1;
    	transition: .4s;
    	z-index: 10000;
    	left: 0;

    	@media (min-width: 1880px) {
	    	padding: 0px 13%;
	    }
    }

    .dropDownLink {
    	position: relative;
	    width: 100%;
	    margin: 9vh auto 12vh;
	    font-size: 35px;
	    font-family: niveau-grotesk, sans-serif;
	    font-weight: 400;
	    text-align: center;
	    text-decoration: none;
	    cursor: pointer;
	    opacity: 0;
	    transition: .8s;
	    transition-delay: 0s;

		@media (min-width: 768px) {
			font-size: 55px;
   			margin: 3vh auto 13vh;
		}

		@media (min-width: 1440px) {
			font-size: 50px;
			margin: 2vh auto 14vh;
			width: 11%;
		}

	    &.expand {
    		opacity: 1;
    		@media (min-width: 1440px) {
    			transition-delay: 0s;
    			// transition: .4s;
			}

			&:hover {
				transform: scale(1.5);
				transition-delay: 0s !important;
				transition: .4s;
			}
    	}

    	&.about {
			&:hover {
				.underDrop {
					transition-delay: 0s !important;
					transition: .7s !important;
					background: #26ffba;
				}
			}	
    	}

    	&.contact {
			&:hover {
				.underDrop {
					transition-delay: 0s !important;
					transition: .7s !important;
					background: #26ffba;
				}
			}	
    	}

    	&.resume {
			&:hover {
				.underDrop {
					transition-delay: 0s !important;
					transition: .7s !important;
					background: #26ffba;
				}
			}	
    	}

    	a {
    		text-decoration: none;
    		// color: white;

    		&.work {
    			color: white;
    			transition: .7s;
	    		transition-delay: .4s;

	    		@media (min-width: 1440px) {
	    			transition-delay: 0s;
				}

				&:hover {
					transform: scale(1.5);
					transition-delay: 0s !important;
					transition: .7s;

					.underDrop {
						transition-delay: 0s;
						transition: .7s;
						background: #26ffba;
					}
				}
	    	}

	    	&.about {
    			color: white;
    			transition: .7s;
	    		transition-delay: .2s;

	    		@media (min-width: 1440px) {
	    			transition-delay: 0s;
				}

				&:hover {
					transform: scale(1.5);
					transition-delay: 0s !important;
					transition: .7s;

					.underDrop {
						transition-delay: 0s;
						transition: .7s;
					}
				}
	    	}

	    	&.contact {
	    		color: white;
	    		transition-delay: .3s;

	    		@media (min-width: 1440px) {
	    			transition-delay: 0s;
				}

				&:hover {
					transform: scale(1.5);
					transition-delay: 0s !important;
					transition: .4s;
				}
	    	}

	    	&.resume {
    			color: white;
	    		transition-delay: .4s;

	    		@media (min-width: 1440px) {
	    			transition-delay: 0s;
				}

				&:hover {
					transform: scale(1.5);
					transition-delay: 0s !important;
					transition: .4s;
				}
	    	}
    	}
    }

    .underDrop {
    	position: relative;
    	margin: 7px auto 0px;
    	width: 21%;
    	height: 2px;
    	background: white;
    	opacity: 0;
    	transition: .7s;
    	transform: scaleX(0);

		@media (min-width: 768px) {
			height: 4px;
			margin-top: 12px;
			width: 14%;
		}

		@media (min-width: 1440px) {
		    width: 66%;
   		 	margin-top: 8px;
		}

    	&.expand {
    		opacity: 1;
    		transform: scaleX(1);
    	}

    	&.about {
    		width: 24%;
    		transition-delay: .2s;

    		@media (min-width: 768px) {
			    width: 18%;
			}

			@media (min-width: 1440px) {
			    width: 74%;
			}
    	}

    	&.contact {
    		width: 29%;
    		transition-delay: .3s;

    		@media (min-width: 768px) {
			    width: 21%;
			}

			@media (min-width: 1440px) {
			    width: 89%;
			}
    	}

    	&.resume {
    		width: 30%;
    		transition-delay: .4s;

    		@media (min-width: 768px) {
			    width: 21%;
			}

			@media (min-width: 1440px) {
			    width: 91%;
			}
    	}
    }
}


























