/* 3. Header ================================= */
.headerContainer {
	position: fixed;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 90px;
	background: black;
	z-index: 1000;
	cursor: pointer;
}

@media (min-width: 768px) {
	.headerContainer {
		height: 180px;
	}
}

@media (min-width: 1440px) {
	.headerContainer {
		height: 140px;
	}
}

.headerContainer .headerLogo {
	background: url(../assets/logos/wordmarkWhite.svg) no-repeat;
	position: relative;
	width: 60px;
	height: 60px;
	margin: 10px 0px 10px 6%;
	transition: .4s;
	transform: rotate(0deg);
}

@media (min-width: 768px) {
	.headerContainer .headerLogo {
		width: 110px;
		height: 110px;
		margin-left: 4%;
		margin-top: 7px;
	}
}

@media (min-width: 1440px) {
	.headerContainer .headerLogo {
		margin-left: 0;
		margin-top: 5px;
		width: 160px;
		height: 130px;
		background-position: center;
		background-size: 65%;
	}
	.headerContainer .headerLogo:hover {
		transform-origin: center;
		transform: rotate(90deg) scale(0.8);
		transition: .4s;
	}
}

.headerContainer .headerLogo a {
	position: absolute;
	width: 100%;
	height: 100%;
	margin: 0 auto;
}

.headerContainer .menuContainer {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	margin-top: 0px;
	right: 8%;
	width: 40px;
	height: 40px;
	border: 2px solid transparent;
	border-radius: 50%;
	transition: .4s;
}

@media (min-width: 768px) {
	.headerContainer .menuContainer {
		right: 5%;
		width: 60px;
		height: 62px;
	}
}

@media (min-width: 1440px) {
	.headerContainer .menuContainer {
		right: 2%;
		width: 50px;
		height: 54px;
	}
}

.headerContainer .menuContainer.menuCircle {
	border: 3px solid white;
	transition: .4s;
	transform-origin: center;
	transform: scale(1.15) rotate(90deg);
}

@media (min-width: 768px) {
	.headerContainer .menuContainer.menuCircle {
		border: 4px solid white;
	}
}

.headerContainer .menuContainer .menuLine {
	position: relative;
	margin: 0 auto;
	width: 100%;
	height: 3px;
	background: white;
	transition: .4s;
	transform-origin: center;
	opacity: 1;
}

@media (min-width: 768px) {
	.headerContainer .menuContainer .menuLine {
		height: 4px;
	}
}

.headerContainer .menuContainer .menuLine.menuLineBlue {
	background: #26ffba;
	transition-delay: 0;
}

.headerContainer .menuContainer .topLine {
	transition-delay: .2s;
}

.headerContainer .menuContainer .topLine.topClose {
	opacity: 0;
	transition: .4s;
}

.headerContainer .menuContainer .midLine.midClose {
	transform: rotate(135deg);
	transition: .6s;
	width: 70%;
}

.headerContainer .menuContainer .midLine2 {
	position: absolute;
	margin-top: -1px;
	opacity: 0;
}

.headerContainer .menuContainer .midLine2.midClose2 {
	transform: rotate(45deg);
	transition: .6s;
	width: 70%;
	opacity: 1;
}

@media (min-width: 768px) {
	.headerContainer .menuContainer .midLine2.midClose2 {
		margin-top: 0px;
	}
}

.headerContainer .menuContainer .bottomLine {
	transition-delay: .2s;
}

.headerContainer .menuContainer .bottomLine.bottomClose {
	opacity: 0;
	transition: .4s;
}

.dropDownContainer {
	position: fixed;
	margin-top: 0px;
	left: 0;
	height: 100%;
	width: 100%;
	background: black;
	z-index: 10;
	transition-timing-function: cubic-bezier(0.27, 0.21, 0.21, 1);
	transition: .5s;
	transition-delay: .55s;
	opacity: 0;
	z-index: -100;
}

.dropDownContainer.expand {
	opacity: 1;
	transition: .4s;
	z-index: 10000;
	left: 0;
}

@media (min-width: 1880px) {
	.dropDownContainer.expand {
		padding: 0px 13%;
	}
}

.dropDownContainer .dropDownLink {
	position: relative;
	width: 100%;
	margin: 9vh auto 12vh;
	font-size: 35px;
	font-family: niveau-grotesk, sans-serif;
	font-weight: 400;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	opacity: 0;
	transition: .8s;
	transition-delay: 0s;
}

@media (min-width: 768px) {
	.dropDownContainer .dropDownLink {
		font-size: 55px;
		margin: 3vh auto 13vh;
	}
}

@media (min-width: 1440px) {
	.dropDownContainer .dropDownLink {
		font-size: 50px;
		margin: 2vh auto 14vh;
		width: 11%;
	}
}

.dropDownContainer .dropDownLink.expand {
	opacity: 1;
}

@media (min-width: 1440px) {
	.dropDownContainer .dropDownLink.expand {
		transition-delay: 0s;
	}
}

.dropDownContainer .dropDownLink.expand:hover {
	transform: scale(1.5);
	transition-delay: 0s !important;
	transition: .4s;
}

.dropDownContainer .dropDownLink.about:hover .underDrop {
	transition-delay: 0s !important;
	transition: .7s !important;
	background: #26ffba;
}

.dropDownContainer .dropDownLink.contact:hover .underDrop {
	transition-delay: 0s !important;
	transition: .7s !important;
	background: #26ffba;
}

.dropDownContainer .dropDownLink.resume:hover .underDrop {
	transition-delay: 0s !important;
	transition: .7s !important;
	background: #26ffba;
}

.dropDownContainer .dropDownLink a {
	text-decoration: none;
}

.dropDownContainer .dropDownLink a.work {
	color: white;
	transition: .7s;
	transition-delay: .4s;
}

@media (min-width: 1440px) {
	.dropDownContainer .dropDownLink a.work {
		transition-delay: 0s;
	}
}

.dropDownContainer .dropDownLink a.work:hover {
	transform: scale(1.5);
	transition-delay: 0s !important;
	transition: .7s;
}

.dropDownContainer .dropDownLink a.work:hover .underDrop {
	transition-delay: 0s;
	transition: .7s;
	background: #26ffba;
}

.dropDownContainer .dropDownLink a.about {
	color: white;
	transition: .7s;
	transition-delay: .2s;
}

@media (min-width: 1440px) {
	.dropDownContainer .dropDownLink a.about {
		transition-delay: 0s;
	}
}

.dropDownContainer .dropDownLink a.about:hover {
	transform: scale(1.5);
	transition-delay: 0s !important;
	transition: .7s;
}

.dropDownContainer .dropDownLink a.about:hover .underDrop {
	transition-delay: 0s;
	transition: .7s;
}

.dropDownContainer .dropDownLink a.contact {
	color: white;
	transition-delay: .3s;
}

@media (min-width: 1440px) {
	.dropDownContainer .dropDownLink a.contact {
		transition-delay: 0s;
	}
}

.dropDownContainer .dropDownLink a.contact:hover {
	transform: scale(1.5);
	transition-delay: 0s !important;
	transition: .4s;
}

.dropDownContainer .dropDownLink a.resume {
	color: white;
	transition-delay: .4s;
}

@media (min-width: 1440px) {
	.dropDownContainer .dropDownLink a.resume {
		transition-delay: 0s;
	}
}

.dropDownContainer .dropDownLink a.resume:hover {
	transform: scale(1.5);
	transition-delay: 0s !important;
	transition: .4s;
}

.dropDownContainer .underDrop {
	position: relative;
	margin: 7px auto 0px;
	width: 21%;
	height: 2px;
	background: white;
	opacity: 0;
	transition: .7s;
	transform: scaleX(0);
}

@media (min-width: 768px) {
	.dropDownContainer .underDrop {
		height: 4px;
		margin-top: 12px;
		width: 14%;
	}
}

@media (min-width: 1440px) {
	.dropDownContainer .underDrop {
		width: 66%;
		margin-top: 8px;
	}
}

.dropDownContainer .underDrop.expand {
	opacity: 1;
	transform: scaleX(1);
}

.dropDownContainer .underDrop.about {
	width: 24%;
	transition-delay: .2s;
}

@media (min-width: 768px) {
	.dropDownContainer .underDrop.about {
		width: 18%;
	}
}

@media (min-width: 1440px) {
	.dropDownContainer .underDrop.about {
		width: 74%;
	}
}

.dropDownContainer .underDrop.contact {
	width: 29%;
	transition-delay: .3s;
}

@media (min-width: 768px) {
	.dropDownContainer .underDrop.contact {
		width: 21%;
	}
}

@media (min-width: 1440px) {
	.dropDownContainer .underDrop.contact {
		width: 89%;
	}
}

.dropDownContainer .underDrop.resume {
	width: 30%;
	transition-delay: .4s;
}

@media (min-width: 768px) {
	.dropDownContainer .underDrop.resume {
		width: 21%;
	}
}

@media (min-width: 1440px) {
	.dropDownContainer .underDrop.resume {
		width: 91%;
	}
}
